* {
  box-sizing: border-box;
}

.charactericon {
  width: 300px;
  min-height: 120px;
  flex-grow: 1;
  border-left: 0.4rem solid #252526;
  padding: 0;
  text-align: center;
  margin-bottom: 0.4rem;
  user-select: none;
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  text-shadow: 3px 3px 4px #111, 3px 3px 4px #111;
  font-family: "OpenSans", sans-serif;
  font-size: 24pt;
  text-decoration: none;
}

.row {
  max-width: 85rem;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.charactericon:hover {
  color:white;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: .8;
}

.KnightIcon{
  background-image: url(./../img/knight_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 50px;
  width: 50px;
  top: 1.5em;
  right: 1.3em;
}

.VikingIcon{
  background-image: url(./../img/viking_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 50px;
  width: 50px;
  top: 1.5em;
  right: 1.4em;
}

.SamuraiIcon{
  background-image: url(./../img/samurai_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 50px;
  width: 50px;
  top: 1.5em;
  right: 1.3em;
}

.WuLinIcon{
  background-image: url(./../img/wu_lin_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 50px;
  width: 50px;
  top: 1.5em;
  right: 1.35em;
}

.OutlanderIcon{
  background-image: url(./../img/outlander_icon.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  height: 50px;
  width: 50px;
  top: 1.5em;
  right: 1.3em;
}

.KnightDivider {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #C29124 60%, #C2912400) 1;
}

.VikingDivider {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #821515 60%, #82151500) 1;
}

.SamuraiDivider {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #15897d 60%, #15897d00 ) 1;
}

.WuLinDivider {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #68348A 60%, #68348A00) 1;
}

.OutlanderDivider {
  border-left: 6px solid;
  border-image: linear-gradient(to bottom, #005DA0 60%, #005DA000 ) 1;
}

.Warden {
  background-image: url(./../img/warden.png);
  background-position: center 35%;
}

.Conqueror {
  background-image: url(./../img/conqueror.png);
  background-position: center 30%;
}

.Peacekeeper {
  background-image: url(./../img/peacekeeper.png);
  background-position: center;
}

.Lawbringer {
  background-image: url(./../img/lawbringer.png);
  background-position: center 20%;
}

.Centurion {
  background-image: url(./../img/centurion.png);
  background-position: center 22%;
}

.Gladiator {
  background-image: url(./../img/gladiator.png);
  background-position: center 30%;
}

.BlackPrior {
  background-image: url(./../img/blackprior.png);
  background-position: center 25%;
}

.Warmonger {
  background-image: url(./../img/warmonger.png);
  background-position: center 29%;
}

.Gryphon {
  background-image: url(./../img/gryphon.png);
  background-position: center 40%;
}

.Raider {
  background-image: url(./../img/raider.png);
  background-position: center 30%;
}

.Warlord {
  background-image: url(./../img/warlord.png);
  background-position: center 40%;
}

.Berserker {
  background-image: url(./../img/berserker.png);
  background-position: center 30%;
}

.Valkyrie {
  background-image: url(./../img/valkyrie.png);
  background-position: center 35%;
}

.Highlander {
  background-image: url(./../img/highlander.png);
  background-position: center 30%;
}

.Shaman {
  background-image: url(./../img/shaman.png);
  background-position: center 40%;
}

.Jormungandr {
  background-image: url(./../img/jormungandr.png);
  background-position: center 15%;
}

.Varangian {
  background-image: url(./../img/varangian.png);
  background-position: center 57%;
  line-height: 103%;
}

.Kensei {
  background-image: url(./../img/kensei.png);
  background-position: center 27%;
}

.Shugoki {
  background-image: url(./../img/shugoki.png);
  background-position: center 50%;
}

.Orochi {
  background-image: url(./../img/orochi.png);
  background-position: center 30%;
}

.Nobushi {
  background-image: url(./../img/nobushi.png);
  background-position: center 22%;
}

.Shinobi {
  background-image: url(./../img/shinobi.png);
  background-position: center 7%;
}

.Aramusha {
  background-image: url(./../img/aramusha.png);
  background-position: center 30%;
}

.Hitokiri {
  background-image: url(./../img/hitokiri.png);
  background-position: center 20%;
}

.Kyoshin {
  background-image: url(./../img/kyoshin.png);
  background-position: center 35%;
}

.Sohei {
  background-image: url(./../img/sohei.png);
  background-position: center 40%;
}

.Tiandi {
  background-image: url(./../img/tiandi.png);
  background-position: center 35%;
}

.JiangJun {
  background-image: url(./../img/jiangjun.png);
  background-position: center 25%;
}

.Nuxia {
  background-image: url(./../img/nuxia.png);
  background-position: center 20%;
}

.Shaolin {
  background-image: url(./../img/shaolin.png);
  background-position: center 55%;
}

.Zhanhu {
  background-image: url(./../img/zhanhu.png);
  background-position: center 20%;
}

.Pirate {
  background-image: url(./../img/pirate.png);
  background-position: center 32%;
}

.Medjay {
  background-image: url(./../img/medjay.png);
  background-position: center 40%;
}

.Afeera {
  background-image: url(./../img/afeera.png);
  background-position: center 39%;
}

.Ocelotl {
  background-image: url(./../img/ocelotl.png);
  background-position: center 40%;
}

.Khatun {
  background-image: url(./../img/khatun.png);
  background-position: center 28%;
}