.tier1 {
  width: 10%;
  min-width: 63px;
  background-color: #4584f7;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.tier2 {
  width: 10%;
  min-width: 63px;
  background-color: #1b5acf;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.tier3 {
  width: 10%;
  min-width: 63px;
  background-color: #193d80;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.tier4 {
  width: 10%;
  min-width: 63px;
  background-color: #072866;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.Offense {
  width: 10%;
  min-width: 63px;
  background-color: #821515;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.Assist {
  width: 10%;
  min-width: 63px;
  background-color: #2D885A;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.Defense {
  width: 10%;
  min-width: 63px;
  background-color: #005DA0;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
  text-shadow: 2px 2px rgb(49, 49, 49);
}

.tier-and-feats {
  display: flex;
  flex-direction: row;
  margin: 1%;
}
.feats {
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: pre-line;
}

.perks {
  display: flex;
  width: 90%;
  flex-direction: row;
  flex-wrap: wrap;
  white-space: pre-line;
}

.perksTable {
  background-color: #252526;
  border-color: #252526 !important;
  text-align: center;
}

@media only screen and (min-width: 596px) {
.tableBox {
  padding-left: 20%;
  padding-right: 20%;
  }
}

@media only screen and (max-width: 596px) {
.Offense {
font-size: 1.1em;
}

.Defense {
  font-size: 1.1em;
}

.Assist {
  font-size: 1.1em
}
}