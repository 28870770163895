.MuiListItemButton-root {
  white-space: pre-line;
}

.MuiListSubheader-root {
  color: black !important;
  font-size: 1.5rem !important;
  text-align: center !important;
}

.MuiList-root {
  background-color: rgb(58, 58, 58) !important;
  box-shadow: 3px 3px 3px rgb(36, 36, 36) !important;
}

.ListItemNotes{
  background-color: rgb(45, 45, 45) !important;
}