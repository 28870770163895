.sr-only {
  display: none;
}
.react-bootstrap-table .caret {
  display: inline-block;
  margin-left: 0;
  vertical-align: 0.255em;
  content: "";
  border-right: 0.25em solid transparent;
  border-left: 0.25em solid transparent;
  border-top: 0.25em solid;
  border-bottom: 0;
  opacity: 0.35;
  color: white;
}

.react-bootstrap-table{
  padding-top: 1rem;
}

.react-bootstrap-table .dropup .caret {
  border-top: 0;
  border-bottom: 0.3em solid;
  color: white;
}

.react-bootstrap-table .react-bootstrap-table-sort-order .caret {
  opacity: 1;
  color: white;
}

.react-bootstrap-table-sort-order,
.react-bootstrap-table .order {
  margin-left: 5px;
}

.table {
  color: white !important;
  border-color: rgb(158, 158, 158) !important;
  background-color: rgb(41,41,41);
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: white !important;
  border-color: rgb(158, 158, 158);
  background-color: rgb(56,56,56);
}

html {
  scroll-padding-top: 55px; /* height of sticky header */
}

body {
  background-color: #252526 !important;
  color: var(--bs-light) !important;
}

#lessFilteredItems{
  margin-bottom: 179px !important;
}

.MuiButtonBase-root {
  background-color: rgb(58, 58, 58) !important;
}

.dropdown-content {
  color: black;
}

.dropdown-heading-value {
  color: black;
}

h1 {
  font-weight: 600 !important;
}

h2 {
  color: white;
}

.MuiSvgIcon-root {
  color: whitesmoke;
}

.styledLinks{
  color: #CF9803;
  text-decoration: none;
}

.styledLinks:hover{
  color: #b68601;
  text-decoration: none;
}

.MuiCircularProgress-svg{
  color: white !important;
}

.circular-progress{
  position: absolute;
  left:0;
  right: 0;
  margin: auto;
  top: 50%;
  bottom: 50%;
}