.attacks {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.attack {
  margin-top: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  min-width: 320px;
  max-width: 380px;
}

.MuiCardContent-root {
  background-color:  rgb(48, 48, 48);
  color: white;
}


.MuiPaper-root {
  border-radius: 0px !important;
  border-color: #333333 !important;
}

.MuiPaper-root {
  border-radius: 0px !important;
  border-color: #333333 !important;
}

.filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 3px 3px 3px rgb(36, 36, 36);
  position: fixed;
  bottom: 10%;
  right: 0.5%;
  z-index: 5;
  background-color: rgb(58, 58, 58);
}

@media screen and (max-width:490px){
  .filters{
    right: 2%;
    bottom: 13%;
  }
}

.MuiListItemButton-root {
  white-space: pre-line;
}

.MuiListSubheader-root {
  color: white !important;
  font-size: 1.5rem;
  text-align: center;
}

.MuiListSubheader-root {
  background-color: rgb(58, 58, 58);
}

.MuiList-root {
  background-color: rgb(58, 58, 58);
  box-shadow: 3px 3px 3px rgb(36, 36, 36);
}

.MuiListItemButton{
  background-color: rgb(45, 45, 45);
}

.CardText {
  max-width: 220px;
  text-align: right;
}
#nested-list-subheader{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-height: 65px;
}
.propertyIcons{
  display: flex;
  flex-direction: row;
  max-width: 30%;
  flex-wrap: wrap;
  justify-content: center;
  margin-right: 1rem;
}
.propertyImage{
  width: 45px;
  height: 45px;
}

.MuiListItemButton-root:hover {
  background-color: rgb(52, 52, 52) !important
}

.redField{
  background-color: #821515 !important;
}

.blueField{
  background-color: #005DA0 !important;
}

.greenField{
  background-color: #2D885A !important;
}

.redField:hover {
  background-color: #691111 !important;
}

.blueField:hover {
  background-color: #00477a !important;
}

.greenField:hover{
  background-color:#206040 !important;
}
