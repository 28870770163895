.feat {
  width: 13.5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.1%;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 10px;
  border: 1px solid rgb(58, 58, 58) ;
  box-shadow: 3px 3px 3px rgb(36, 36, 36);
  min-width: 300px;
  max-width: 300px;
  min-height: 150px;
  background-color: rgb(45,45,45);
}
.feat-name-and-description {
  display: flex;
  flex-direction: column;
}
.featImage{
  position: absolute;
  margin-top: 3rem;
  margin-left: 1.3rem;
  height: 256px;
  width: 256px;
  z-index: 0;
  opacity: 0.15;
  pointer-events: none;
}

.feat-name {
  padding-top: 3%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.35rem;
  font-weight: 500;
}
.feat-description {
  min-height: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.1rem;
}
.feat-description-explanation {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.05rem;
  padding-left: 4%;
  padding-right: 4%;
}

.feat-statistic-container {
  display: flex;
  height: 154px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-size: 0.9rem;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 3%;
  padding-bottom: 5%;
}

.feat-statistic-heading {
  color: white;
  text-align: center;
}

.popover {
  border: 1px solid rgb(78, 78, 78) !important;
  border-radius: 0px !important;
  background-color: rgba(0,0,0,0) !important;
}

.popover-header {
  background-color: rgb(48, 48, 48) !important;
  border-bottom: 1px solid rgb(78, 78, 78) !important;
}

.popover-body {
  white-space: pre-line;
  background-color:rgb(54, 54, 54) !important;
  color: white !important;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before, .bs-popover-top>.popover-arrow::before{
  border-top-color: rgb(78, 78, 78) !important;
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after, .bs-popover-top>.popover-arrow::after {
  border-top-color: rgb(54, 54, 54) !important;
}
