.tier1 {
  width: 12rem;
  min-width: 63px;
  background-color: #4584f7;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
}

.tier2 {
  width: 12rem;
  min-width: 63px;
  background-color: #1b5acf;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
}

.tier3 {
  width: 12rem;
  min-width: 63px;
  background-color: #193d80;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
}

.tier4 {
  width: 12rem;
  min-width: 63px;
  background-color: #072866;
  border: 1px solid rgb(58, 58, 58);
  text-align: center;
  margin-right: 1%;
  color: white;
  font-size: 2rem;
  padding-top: 1%;
}

.tier-and-feats {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 1%;
  justify-content: center;
}

.feats {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  white-space: pre-line;
}

.perks {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  white-space: pre-line;
}
