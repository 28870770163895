.nav-link:link {
  color: var(--bs-light);
}

.nav-link:hover {
  color: rgb(172, 172, 172);
}

.nav-link:visited {
  color: var(--bs-light);
}

.nav-link:visited:hover {
  color:  rgb(172, 172, 172);
}

.active {
  color: #fcfcfc;
}

.offcanvas {
  background-color: #1D1D1D !important;
  color: var(--bs-light) !important;
}

.navbar{
  position: sticky!important;
  z-index: 9999;
  top:0;
}

.darkmode.navbar {
  background-color: #1D1D1D;
}

.NavbarCloseButton {
  background-color: #252526 !important;
}

.MuiDialog-paper {
  background-color: #252526 !important;
  color: white !important;
}

.nav-link:focus, .nav-link:hover {
  color: whitesmoke !important;
}

.QuestionMarkNavLink, .QuestionMarkNavLink:visited {
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out !important;
  color: rgba(255,255,255,.55);
}

.QuestionMarkNavLink:focus, .QuestionMarkNavLink:hover {
  color: #f5f5f5;
}

.dropdown-menu {
  background-color: #1D1D1D !important;
}

.dropdown-item {
  color: whitesmoke !important;
}

.dropdown-item:hover {
  background-color: #252526 !important;
}

.navbar-brand{
  margin-right: 0.2rem!important; 
}