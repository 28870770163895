.Knights {
  background-color: #C29124;
  padding: 0.25rem;
  text-align: center;
  text-shadow: 2px 2px rgb(49, 49, 49);
  margin-bottom: 3px;
}

.Vikings {
  background-color: #821515;
  padding: 0.25rem;
  text-align: center;
  text-shadow: 2px 2px rgb(49, 49, 49);
  margin-bottom: 3px;
}

.Samurai {
  background-color: #15897d;
  padding: 0.25rem;
  text-align: center;
  text-shadow: 2px 2px rgb(49, 49, 49);
  margin-bottom: 3px;
}

.Wu-Lin {
  background-color: #68348A;
  padding: 0.25rem;
  text-align: center;
  text-shadow: 2px 2px rgb(49, 49, 49);
  margin-bottom: 3px;
}

.Outlanders {
  background-color: #005DA0;
  padding: 0.25rem;
  text-align: center;
  text-shadow: 2px 2px rgb(49, 49, 49);
  margin-bottom: 3px;
}