.walk-speed-header {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.MuiTableHead-root{
  text-align: center !important;
  color: white !important;
  border-bottom-color: transparent !important;
}
.MuiTableCell-head{
  text-align: center !important;
  font-weight: bold !important;
  color: white !important;
  border-bottom-color: gray !important;
}

.MuiTableCell-body{
  text-align: center !important;
  color: white !important;
  border-bottom-color: transparent !important;
}

.walk-speed-types {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 150px !important;
  align-items: center;
}
.walk-speed-values {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 150px !important;
  align-items: center;
}
.walk-speed-values>div{
  min-width: 33%;
  display: flex;
  justify-content: center;
}
.walk-speed-types>div{
  min-width: 33%;
  display: flex;
  justify-content: center;
}

details > *:not(summary) {
  margin-left: 1.7em;
}

.MuiTypography-body {
  white-space: pre-line;
}
