.perk {
  width: 13.5%;
  display: flex;
  flex-direction: column;
  margin-top: 0.1%;
  margin-left: 0.3%;
  margin-right: 0.3%;
  margin-bottom: 10px;
  border: 1px solid rgb(58, 58, 58) ;
  box-shadow: 3px 3px 3px rgb(36, 36, 36);
  min-width: 300px;
  max-width: 300px;
  min-height: 150px;
  background-color: rgb(45,45,45);
}

.perkHeader1 {
  background-color: rgb(120,120,120);
  color: whitesmoke;
}

.perkHeader2 {
  background-color: rgb(120,120,120);
  color: whitesmoke;
}

.perkHeader3 {
  background-color: rgb(120,120,120);
  color: whitesmoke;
}

.perkHeader4 {
  background-color: #005DA0;
  color: whitesmoke;
}

.perkHeader5 {
  background-color: #68348A;
  color: whitesmoke;
}

.perkHeader6 {
  background-color: #C29124;
  color: whitesmoke;
}

.perkHeader7 {
  background-color: #15897d;
  color: whitesmoke;
}

.perk-name {
  padding-top: 3%;
  padding-bottom: 2%;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-right: 3.4rem;
  font-size: 1.35rem;
  font-weight: 500;
}

.perk-category {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1rem;
  font-weight: 300;
}

.perk-description {
  min-height: 40%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.1rem;
}
.perk-description-explanation {
  min-height: 250px;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1.05rem;
  padding-left: 4%;
  padding-right: 4%;
  white-space: pre-line;
}

.perk-statistic-container {
  display: flex;
  height: 154px;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-size: 0.9rem;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 3%;
  padding-bottom: 5%;
}

.popover-header {
  background-color: rgb(48, 48, 48) !important;
  border-radius: 0px !important;
}

.popover-body {
  white-space: pre-line;
  background-color:rgb(58, 58, 58);
  color: white !important;
  border-radius: 0px !important;
}

.perk-icon {
  float: left;
}

.perkIcon{
  display: flex;
  flex-direction: row;
  margin-top: 0.2rem;
  margin-left: 0.2rem;
  height: 70px;
  width: 70px;
  z-index: 0;
  pointer-events: none;
}

.perkImage{
  image-rendering: crisp-edges;
  position: absolute;
  margin-left: 1.5rem;
  height: 250px;
  width: 250px;
  z-index: 0;
  opacity: 0.15;
  pointer-events: none;
}